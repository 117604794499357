export default {
  auth: {
    login: 'login',
    verify: 'users/me',
  },
  meetings: {
    live: 'meetings/live',
  },
  videos: {
    list: 'videos/search',
    detail: id => `videos/${id}`,
  },
}
