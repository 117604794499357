'use strict'

import apiField from '@/plugins/apiField'
import { SearchBasic } from '@/plugins/params'
import shenlearnAPI, { handleRequest } from '@/plugins/shenlearnAPI'
import videoInfoFormat from '@/plugins/videoInfoFormat'
import { flow, result, sortBy, unionBy } from 'lodash/fp'
import moment from 'moment'

const defaultParams = new SearchBasic({
  limit: 24,
  offset: 0,
})

const namespace = 'videos'

const namespaced = true

const stateMap = {
  list: 'list',
  total: 'total',
}
const state = {
  [stateMap.list]: [],
  [stateMap.total]: Number.MAX_SAFE_INTEGER,
}

const gettersMap = {
  videoList: 'videoList',
  hasAllItems: 'hasAllItems',
}

/**
 * @type {import('vuex').GetterTree}
 */
const getters = {
  [gettersMap.videoList]: (state) =>
    state[stateMap.list].map(videoInfoFormat),

  [gettersMap.hasAllItems]: (state) =>
    state[stateMap.list].length >= state[stateMap.total],
}

const mutationsMap = {
  setList: 'setList',
  setTotal: 'setTotal',
}

/**
 * @type {import('vuex').MutationTree}
 */
const mutations = {
  [mutationsMap.setList]: (state, payload) => {
    state[stateMap.list] = payload
  },

  [mutationsMap.setTotal]: (state, payload) => {
    state[stateMap.total] = payload
  },
}

const actionsMap = {
  read: 'read',
}

/**
 * @type {import('vuex').ActionTree}
 */
const actions = {
  [actionsMap.read]: async({ state, commit }, p = {}) => {
    defaultParams.reset().setParams(p)
    const api = apiField.videos.list
    const params = defaultParams.getParams()
    const { data = [], pagination = {} } = await handleRequest(shenlearnAPI.get(api, { params }))
    const { total = 0 } = pagination
    const oldList = state[stateMap.list]
    const list = flow([
      unionBy('id')(oldList),
      sortBy(flow([
        moment,
        result('valueOf'),
      ])),
    ])(data)

    commit(mutationsMap.setList, list)
    commit(mutationsMap.setTotal, total)
  },
}

export const videosMap = {
  namespace,
  stateMap,
  gettersMap,
  mutationsMap,
  actionsMap,
}
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
