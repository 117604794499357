<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="auto"
        class="pa-0 pa-md-3 grow"
        style="width: min-content;"
      >
        <v-sheet
          dark
          color="black"
        >
          <v-responsive
            :aspect-ratio="16/9"
          >
            <v-row
              v-if="videoInfo.__isExpired"
              no-gutters
              align="center"
              justify="center"
              class="fill-height"
            >
              <span
                v-text="$t('homeVideos.isExpired')"
              />
            </v-row>

            <iframe
              ref="iframe"
              v-else-if="videoInfo.__playSrc"
              v-intersect="iframeAutoplay"
              :src="videoInfo.__playSrc"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
              style="border: none; position:absolute; top:0; left:0; width: 100%; height:100%; aspect-ratio: 16/9;"
            />
          </v-responsive>
        </v-sheet>

        <v-card
          v-if="video"
          flat
          tile
          color="transparent"
        >
          <v-card-title
            class="px-md-0 text-md-h6"
          >
            {{ videoInfo.name }}
          </v-card-title>

          <v-card-subtitle
            class="px-md-0"
          >
            <div>
              <span
                v-text="videoInfo.__createdAt"
              />
            </div>
            <div>
              <span
                v-text="$t('util.expiredDate')"
              />
              :
              <span
                v-text="videoInfo.__expiredDate"
              />
            </div>
          </v-card-subtitle>

          <v-card-text
            class="px-md-0"
          >
            <v-divider />

            <div
              class="py-3"
              v-html="videoInfo.__remark"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="auto"
        class="pl-md-0"
        style="flex-basis: 426px"
      >
        <v-list
          subheader
          color="transparent"
          class="ma-n1"
        >
          <v-list-item
            v-for="(item, index) in showList"
            :key="index"
            :to="{
              name: 'Watch',
              params: {
                videoId: item.__v,
              },
            }"
            v-ripple="false"
            class="pa-1 rounded-sm align-start"
          >
            <v-sheet
              tile
              width="168"
              class="mr-2"
            >
              <v-responsive
                :aspect-ratio="16/9"
                width="100%"
              >
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                  class="fill-height"
                >
                  <v-img
                    v-if="item.__images"
                    :src="item.__images.mq"
                    :aspect-ratio="16/9"
                    :alt="item.name"
                    width="100%"
                    contain
                  />
                  <v-icon
                    v-else
                    size="4rem"
                  >
                    mdi-video
                  </v-icon>
                </v-row>
              </v-responsive>
            </v-sheet>

            <div>
              <span
                class="text-subtitle-1"
                v-text="item.name"
              />

              <div
                class="text-body-2 text--secondary"
              >
                <div>
                  <span
                    v-text="item.__createdAt"
                  />
                </div>
                <div>
                  <span
                    v-text="`${$t('util.expiredDate')}: ${item.__expiredDate}`"
                  />
                </div>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import apiField from '@/plugins/apiField'
import shenlearnAPI, { handleRequest } from '@/plugins/shenlearnAPI'
import { videosMap } from '@/store/videos'
import videoInfoFormat from '@/plugins/videoInfoFormat'
import { flow, reject } from 'lodash/fp'
import { tubePattern } from '@/config'
import { PeerTubePlayer } from '@peertube/embed-api'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Watch',

  components: {
    VueMarkdown,
  },

  props: {
    videoId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      video: null,
    }
  },

  computed: {
    ...mapState('auth', {
      user: 'user',
    }),

    ...mapGetters(videosMap.namespace, {
      items: videosMap.gettersMap.videoList,
    }),

    showList: ({ items, videoId }) =>
      flow([
        reject('__hasRedirect'),
        reject([ '__v', videoId ]),
      ])(items),

    studentId: ({ user }) =>
      user.hashedId,

    videoInfo: ({ video }) =>
      video ? videoInfoFormat(video) : {},
  },

  watch: {
    videoId: {
      handler: 'watchVideoId',
      immediate: true,
    },
  },

  created() {
    if (this.items.length === 0) {
      this.read()
        .catch((error) => {
          console.log([error])
        })
    }
  },

  methods: {
    ...mapActions(videosMap.namespace, {
      read: videosMap.actionsMap.read,
    }),

    async watchVideoId(id) {
      this.video = null
      const api = apiField.videos.detail(id)
      try {
        const res = await handleRequest(shenlearnAPI.get(api))
        this.video = res
        await handleRequest(shenlearnAPI.post(this.videoInfo.packedUrl, {}, { params: { isRedirect: false } }))
      } catch (error) {
        console.log([error])
      }
    },

    async iframeAutoplay() {
      if (tubePattern.test(this.videoInfo.__playSrc)) {
        const player = new PeerTubePlayer(this.$refs.iframe)
        try {
          await player.ready
          player.play()
        } catch (error) {
          console.log([error])
        }
      }
    },
  },
}
</script>
