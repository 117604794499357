import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import videos, { videosMap } from './videos'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    [videosMap.namespace]: videos,
  },
})
