<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-form @submit.prevent="login">
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>
                    {{ $t('login.title') }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="loginForm.username"
                    :label="$t('login.name')"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    v-model="loginForm.password"
                    :label="$t('login.password')"
                    name="password"
                    autocomplete="true"
                    prepend-icon="mdi-lock"
                    type="password"
                  />

                  <v-checkbox
                    v-model="isRemember"
                    :label="$t('login.remember')"
                    hide-details
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="isLoading"
                  >
                    {{ $t('login.submit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Login',

  data() {
    return {
      isLoading: false,
      loginForm: {
        username: null,
        password: null,
      },
      isRemember: false,
    }
  },

  methods: {
    ...mapMutations('auth', {
      rememberMe: 'rememberMe',
    }),
    ...mapActions('auth', {
      loginUser: 'loginUser',
    }),

    async login() {
      this.isLoading = true
      try {
        const isLoginSuccess = await this.loginUser(this.loginForm)
        if (isLoginSuccess) {
          if (this.isRemember) {
            this.rememberMe()
          }

          this.$router.push({
            name: 'Fetch',
            query: {
              originTo: '/',
            },
          })
        }
      } catch (error) {
        const em = this.getErrorMessage(error)
        alert(em)
      }
      this.isLoading = false
    },

    /**
     * @param {import('axios').AxiosError} error
     */
    getErrorMessage(error) {
      console.log([error])
      if (!error) {
        return
      }
      let m = '未知錯誤'
      if (error?.response) {
        const re = error.response
        if (re?.data?.[0]) {
          const rem = re.data[0]
          // EX: "E003409001: Duplicate permission name"
          m = `${rem.code}: ${rem.message}`
        } else if (re?.data?.error) {
          const rem = re.data.error
          // EX: "403: Account not existed"
          m = `${rem.code}: ${rem.message}`
        } else {
          // EX: "409: Conflict"
          m = `${re.status}: ${re.statusText}`
        }
      } else {
        // EX: "Request failed with status code 409"
        m = error.message
      }

      return m
    },
  },
}
</script>
