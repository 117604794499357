<template>
  <v-app>
    <v-main>
      <v-container class="fill-height">
        <v-row
          align="center"
          class="flex-column"
        >
          <v-progress-circular
            indeterminate
            size="56"
          />
          <v-subheader>
            登入中，請稍後...
          </v-subheader>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'PagesLogin',

  async created() {
    try {
      await this.fetchUser()

      this.$router.replace(this.$route.query.originTo)
    } catch (error) {

      this.logout()
      this.$router.replace({
        name: 'Login',
      })
    }
  },

  methods: {
    ...mapActions('auth', {
      fetchUser: 'fetchUser',
      logout: 'logout',
    }),
  },
}
</script>
