'use strict'

import apiField from '@/plugins/apiField'
import shenlearnAPI, { getBasic, handleRequest, accessToken } from '@/plugins/shenlearnAPI'
import {xDomain} from '@/config'

const namespaced = true

const state = {
  accessToken: accessToken,
  chatToken: '',
  user: null,
  error: false,
}

/**
 * @type {import('vuex').GetterTree}
 */
const getters = {
  basic: (state) =>
    ({
      ...getBasic(state.user),
      // id: findItem(userPositions)(state.user?.position),
    }),

  userName(state) {
    const { user } = state
    return user?.name ?? ''
  },
  position(state) {
    const { user } = state
    return user?.position ?? 'student'
  },
  id(state) {
    const { user } = state
    return user?.id ?? null
  },
}

/**
 * @type {import('vuex').MutationTree}
 */
const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    sessionStorage.setItem('accessToken', accessToken)
  },
  setProfile(state, payload) {
    state.error = false
    // state.chatToken = payload.chatToken
    state.user = payload
  },
  clearProfile(state) {
    state.accessToken = ''
    state.chatToken = ''
    state.user = null
  },
  profileError(state) {
    state.error = true
    state.accessToken = ''
    state.chatToken = ''
    state.user = null
  },
  rememberMe(state) {
    console.log('rememberMe', state.accessToken)
    localStorage.setItem('accessToken', state.accessToken)
  },
}

/**
 * @type {import('vuex').ActionTree}
 */
const actions = {
  async loginUser({ commit, dispatch }, submit) {
    try {
      const api = apiField.auth.login
      const data = submit
      const response = await handleRequest(shenlearnAPI.post(api, data, { headers: { 'x-domain': xDomain } }))
      dispatch('setUserProfile', response)
      return true
    } catch (error) {
      commit('profileError')
      throw error
    }
  },
  async fetchUser({ commit }) {
    try {
      const response = await handleRequest(shenlearnAPI.get(apiField.auth.verify))
      commit('setProfile', response)
    } catch (error) {
      commit('profileError')
      throw error
    }
  },
  setUserProfile({ commit }, response = {}) {
    const { accessToken = '' } = response
    shenlearnAPI.defaults.headers.common.authorization = `Bearer ${accessToken}`
    commit('setAccessToken', accessToken)
  },
  async logout({ commit }) {
    commit('clearProfile')
    localStorage.clear()
    sessionStorage.clear()
    delete shenlearnAPI.defaults.headers.common.authorization
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
