import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const lightTheme = {
  primary: '#117391',
  secondary: '#145871',
  accent: '#9c27b0',
  error: '#e91e63',
  warning: '#f2870d',
  info: '#20b291',
  success: '#4caf50',
}

const darkTheme = {
  primary: '#1697bf',
  // primary: '#145871',
  secondary: '#145871',
  accent: '#9c27b0',
  error: '#e91e63',
  warning: '#f2870d',
  info: '#20b291',
  success: '#4caf50',
}

export default new Vuetify({
  theme: {
    dark: !!localStorage.getItem('darkMode'),
    options: {
      customProperties: true,
    },
    themes: {
      dark: darkTheme,
      light: lightTheme,
    },
  },
});
