<template>
  <v-container fluid>
<!--    <home-lives />-->

    <home-videos />
  </v-container>
</template>

<script>
import HomeLives from './HomeLives.vue'
import HomeVideos from './HomeVideos.vue'

export default {
  name: 'Main',
  components: {
    HomeLives,
    HomeVideos,
  },
}
</script>
