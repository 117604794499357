'use strict'

import { YTPattern, needGetEmbedPatterns, tubePattern } from '@/config'
import { formatMoment, getURL } from './shenlearnAPI'
import linkifyStr from 'linkifyjs/string'
import { truncate } from 'lodash/fp'
import moment from 'moment'

/**
 * @function
 * @param {string} url
 */
function hasRedirect(url) {
  const _url = getURL(url)
  if (!(_url instanceof URL)) return false
  if (needGetEmbedPatterns.some(regex => regex.test(_url.href))) return false

  return true
}

/**
 * @function
 * @param {string} url
 */
function getVideoId(url) {
  const _url = getURL(url)
  if (!(_url instanceof URL)) return

  return _url.searchParams.get('v')
}

/**
 * @function
 * @param {string} url
 */
function isReady(url) {
  const _url = getURL(url)
  if (!(_url instanceof URL)) return false

  return true
}

/**
 * @function
 * @param {string} url
 */
function getYTid(url) {
  const _url = getURL(url)
  if (!(_url instanceof URL)) return

  const matches = _url.href.match(YTPattern)
  return matches?.[1]
}

// /**
//  * @param {URL} _url
//  * @returns {string}
//  */
// function getHostTubeId(_url) {
//   if (_url.hostname !== tubeHostname) return

//   const regex = /^\/videos\/watch\/([\w-]{36})$/
//   const matches = _url.pathname.match(regex)

//   return matches?.[1]
// }

/**
 * @function
 * @param {string} url
 */
function getPreviewImgs(url) {
  const YTid = getYTid(url)
  if (YTid) {
    return {
      default: `https://img.youtube.com/vi/${YTid}/default.jpg`,
      sd: `https://img.youtube.com/vi/${YTid}/sddefault.jpg`,
      mq: `https://img.youtube.com/vi/${YTid}/mqdefault.jpg`,
    }
  }
}

/**
 * @function
 * @param {string} url
 */
function getTubeHref(url) {
  const _url = getURL(url)
  if (!(_url instanceof URL)) return

  if (!tubePattern.test(_url.href)) return
  const urlString = _url.href.replace('video.shenlearn.com/videos/watch', 'video.shenlearn.com/videos/embed')
  const _url_ = new URL(urlString)
  _url_.searchParams.set('api', 1)
  _url_.searchParams.set('peertubeLink', 0)
  _url_.searchParams.set('title', 0)
  _url_.searchParams.set('subtitle', 0)
  _url_.searchParams.set('warningTitle', 0)
  return _url_.href
}

/**
 * @function
 * @param {string} url
 */
function getEmbedSrc(url) {
  const YTid = getYTid(url)
  if (YTid) {
    const url = new URL(`https://www.youtube.com/embed/${YTid}`)
    url.searchParams.set('autoplay', 1)
    url.searchParams.set('modestbranding', 1)
    return url.href
  }

  const tubeHref = getTubeHref(url)
  if (tubeHref) {
    return tubeHref
  }

  return url
}

/**
 * @function
 * @param {string} remark
 */
function linkifyRemark(remark) {
  if (typeof remark !== 'string') return
  if (typeof remark === '') return

  const lfRemark = linkifyStr(remark, {
    defaultProtocol: 'https',
    nl2br: true,
    target: {
      url(value) {
        const _url = new URL(value)
        if (_url.origin === window.location.origin) return

        return '_blank'
      },
    },
    format: truncate({
      length: 38,
    }),
    validate(value, type) {
      if (type !== 'url') return false

      return true
    },
  })

  return lfRemark
}

export default function(info) {
  return {
    ...info,
    __createdAt: formatMoment('YYYY-MM-DD')(info.createdAt),
    __expiredDate: formatMoment('YYYY-MM-DD')(info.expiredDate),
    __isExpired: moment().isAfter(info.expiredDate),
    __hasRedirect: hasRedirect(info.url),
    __isReady: isReady(info.url),
    __v: info.id,
    __images: getPreviewImgs(info.url),
    __playSrc: getEmbedSrc(info.url),
    __remark: linkifyRemark(info.remark),
  }
}
