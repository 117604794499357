<template>
  <v-row
    dense
  >
    <v-col
      class="mt-3"
      cols="12"
    >
      <v-row
        no-gutters
        align="center"
      >
        <strong>{{ $t('homeLives.liveChannels') }}</strong>
        <v-spacer />
        <v-btn
          plain
          color="primary"
          @click="readyReload"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          {{ $t('util.reload') }}
        </v-btn>
      </v-row>
    </v-col>

    <v-col
      v-for="(item, index) in showItems"
      :key="index"
      cols="12"
      v-bind="displayCols"
    >
      <v-card
        flat
      >
        <v-card-title>
          <a
            :href="item.zoomJoinUrl"
            :title="item.topic"
            target="_blank"
            rel="noopener noreferrer"
            class="summary-box-single-line"
          >
            {{ item.topic }}
          </a>

          <v-icon right>mdi-export</v-icon>
        </v-card-title>

        <v-card-subtitle>
          <span
            v-text="item.__createdAt"
          />
        </v-card-subtitle>

        <v-card-text>
          <div>
            <span
              :title="item.agenda"
              class="summary-box-three-line"
              v-text="item.agenda"
            />
          </div>
          <v-divider
            class="my-2"
          />
          <div>
            <span
              class="text-body-2 text--secondary"
              v-text="`${$t('util.expiredDate')}: ${item.__expiredDate}`"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <template v-if="!hasAllItems">
      <v-col
        :key="items.length"
        v-intersect="readylist"
        cols="12"
      >
        <v-row
          dense
        >
          <v-col
            v-for="index in showCount"
            :key="index"
            cols="12"
            v-bind="displayCols"
          >
            <v-skeleton-loader
              type="article, divider, list-item"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <v-col
      v-if="!hasShowMore && needShowMore"
      cols="12"
    >
      <v-row
        no-gutters
        align="center"
      >
        <v-divider />

        <v-btn
          text
          color="primary"
          class="mx-2"
          @click="hasShowMore = true"
        >
          {{ $t('util.showMore') }}
        </v-btn>

        <v-divider />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { SearchBasic } from '@/plugins/params'
import apiField from '@/plugins/apiField'
import shenlearnAPI, { handleRequest } from '@/plugins/shenlearnAPI'
import { unionBy } from 'lodash/fp'
import moment from 'moment'
export default {
  name: 'HomeLive',

  data() {
    return {
      items: [],
      displayCols: {
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      },
      defaultParams: new SearchBasic({
        limit: 20,
        offset: 0,
      }),
      hasAllItems: false,
      hasShowMore: false,
      isLoading: false,
    }
  },

  computed: {
    displayItems: ({ items }) =>
      items.map((_) => ({
        ..._,
        __createdAt: moment(_.createdAt).format('YYYY-MM-DD'),
        __expiredAt: moment(_.expiredAt).format('YYYY-MM-DD HH:mm'),
      })),

    showCount: ({ displayCols, $vuetify }) =>
      Math.max(12 / (displayCols[$vuetify.breakpoint.name] ?? 12), 2),

    needShowMore: ({ items, showCount }) =>
      items.length > showCount,

    showItems: ({ displayItems, hasShowMore, showCount }) => {
      if (hasShowMore) return displayItems
      return displayItems.slice(0, showCount)
    },
  },

  methods: {
    readylist() {
      this.defaultParams.reset().setParams({
        limit: 20,
        offset: this.items.length,
      })
      this.list()
    },

    readyReload() {
      this.defaultParams.reset()
      this.items = []
      this.hasAllItems = false
    },

    async list() {
      this.isLoading = true
      const api = apiField.meetings.live
      const params = this.defaultParams.getParams()
      const { data = [], pagination = {} } = await handleRequest(shenlearnAPI.get(api, { params }))
      const { total = 0 } = pagination
      this.items = unionBy('id')(this.items)(data)
      this.hasAllItems = this.items.length >= total
      this.isLoading = false
    }
  },
}
</script>
