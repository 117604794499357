export const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3001/api/e-learning'
  : `${window.location.origin}/api/e-learning`
// export const baseURL = 'http://shenlearn.io/api'

export const watchBaseURL = process.env.NODE_ENV === 'development'
  ? 'https://demo.shenlearn.com/api/v2/videos/watch'
  : `${window.location.origin.replace(/\.tv$/, '.com')}/api/v2/videos/watch`
// export const baseURL = 'http://shenlearn.io/api'

export const tubeHostname = process.env.NODE_ENV === 'development'
  ? 'demo-tube.shenlearn.tv'
  : window.location.hostname.replace(/\.shenlearn\.tv/, '-tube.shenlearn.tv')

export const tubePattern = process.env.NODE_ENV === 'development'
  ? /video\.shenlearn\.com/
  : /video\.shenlearn\.com\/videos/

export const YTPattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?$/

export const xDomain = process.env.NODE_ENV === 'development'
  ? 'lfntu2'
  : window.location.hostname.split('.').shift()

export const actionMehods = Object.freeze({
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
})

export const needGetEmbedPatterns = Object.freeze([
  YTPattern,
  tubePattern,
])
