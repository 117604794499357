<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
table {
  table-layout: fixed;
}

.text-break-word {
  overflow-wrap: break-word;
}

.fixed-height-table {
  > .v-data-table__wrapper {
    overflow-y: scroll !important;
  }
}
</style>
