<template>
  <v-app
    v-if="user"
    id="inspire"
  >
    <v-app-bar
      app
      elevation="0"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      />

      <v-toolbar-title>
        <router-link
          :to="{
            name: 'Main',
          }"
          class="text-decoration-none"
        >
          {{ user.schoolName }}
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ attrs, on }">
          <v-avatar
            color="secondary"
            size="36px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              v-if="user.imageUrl"
              :src="user.imageUrl"
            />

            <v-icon
              v-else
              dark
            >
              mdi-account
            </v-icon>
          </v-avatar>
        </template>

        <v-card
          width="256"
          tile
        >
          <v-list
            subheader
          >
            <v-list-item
              two-line
            >
              <v-list-item-avatar
                color="secondary"
              >
                <v-img
                  v-if="user.imageUrl"
                  :src="user.imageUrl"
                />

                <v-icon
                  v-else
                  dark
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <span
                  v-text="user.name"
                />
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item
              @click="toggleDarkMode"
            >
              <v-list-item-icon>
                <v-icon>mdi-brightness-6</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <span
                  v-text="$t('util.darkMode')"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-switch
                  v-model="$vuetify.theme.dark"
                  readonly
                />
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              @click="handleLogout"
            >
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <span
                  v-text="$t('util.logout')"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
      <v-toolbar
        elevation="0"
      >
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
        />

        <v-toolbar-title>
          <router-link
            :to="{
              name: 'Main',
            }"
            class="text-decoration-none"
          >
            {{ user.schoolName }}
          </router-link>
        </v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            v-bind="item.props"
          >
            <v-list-item-icon>
              <v-icon
                v-text="item.icon"
              />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-text="$t(item.text)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-footer
        id="dashboard-core-footer"
        app
        inset
        absolute
      >
        <v-container fluid>
          <span
            class="text-body-2 text--secondary"
          >
            {{ $t('util.copyright') }}
          </span>
        </v-container>
      </v-footer>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HomeLives from './Home/HomeLives.vue'
import HomeVideos from './Home/HomeVideos.vue'

export default {
  name: 'Home',

  components: {
    HomeLives,
    HomeVideos,
  },

  data() {
    return {
      drawer: null,
      items: [
        {
          text: 'drawer.home',
          icon: 'mdi-home',
          props: {
            to: {
              name: 'Main',
            },
            exact: true,
          },
        },
      ],
      avatarSrc: require('@/assets/logo-shenlearn.png'),
    }
  },

  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
  },

  methods: {
    ...mapActions('auth', {
      logout: 'logout',
    }),

    async handleLogout() {
      await this.logout()
      this.$router.push({
        name: 'Login',
      })
    },

    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if (this.$vuetify.theme.dark) {
        localStorage.setItem('darkMode', '1')
      } else {
        localStorage.removeItem('darkMode')
      }
    },
  },
}
</script>