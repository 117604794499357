'use strict'

import Axios from 'axios'
import moment from 'moment'
import { baseURL } from '@/config'
import { bindKey, curry, filter, find, flow, get, isEmpty, isNumber, isSafeInteger, join, map, mean, parseInt, replace, set, split, startsWith, take, trimEnd, update } from 'lodash/fp'

/**
 * @typedef {object} response
 * @property {number} status
 * @property {string} statusText
 * @property {object} data
 */

export const handleRequest = async p => {
  /** @type {response} */
  const response = await p
  const { data } = response
  return data
}

export const accessToken = localStorage.getItem('accessToken') ?? sessionStorage.getItem('accessToken')

export default Axios.create({
  baseURL,
  headers: accessToken ? {
    common: {
      authorization: `Bearer ${accessToken}`,
    },
  } : undefined,
  responseType: 'json',
})

export const formatHandleAt = (month = undefined) => {
  const d = moment(month).utc(true).set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  return {
    since: d.toISOString(),
    until: d.add({
      months: 1,
      milliseconds: -1,
    }).toISOString(),
  }
}

export const getClass = o => o.class ?? o.course ?? {}

export const getClasses = o => o.classes ?? o.courses ?? []

export const getImages = o => o.images ?? o.courseDiaryImages ?? []

export const takelast = take(5)

export const formatMoment = curry((f, t) => t ? moment(t).utc(true).format(f) : '')

export const getCel = ({
  cellphonePrefix: cpip,
  cellphone: cp,
}) => cp ? `${cpip}${cp}` : ''

export const getTel = ({
  telephonePrefix: tpip,
  telephone: tp,
}) => tp ? `${tpip}${tp}` : ''

export const findItem = curry((m, value) => flow([
  find({ value }),
  get('text'),
])(m))

export const getBasic = _ => ({
  id: _.studentNo ?? _.cardNo ?? _.id,
  name: _.name,
  avatar: _.imageUrl ?? _.avatar ?? _.avatarUrl,
})

export const setBasic = _ => set('__basic')(getBasic(_))(_)

export const spiltTags = split(/\s(?=#)/)

export const filterTags = filter(startsWith('#'))

export const formatTags = map(flow([
  replace(/^#/)(''),
  trimEnd,
]))

export const note2tags = note => note
  ? flow([
      spiltTags,
      formatTags,
    ])(note)
  : []

export const remark2tags = remark => remark
  ? flow([
      spiltTags,
      filterTags,
      formatTags,
    ])(remark)
  : []

export const tags2note = flow([
  map(t => `#${t}`),
  join(' '),
])

export const toFixed = curry((fractionDigits, number) => isNumber(number) ? number.toFixed(fractionDigits) : number)

export const flowAverage = scores => {
  const ss = flow([
    map('score'),
    map(parseInt(10)),
    filter(isSafeInteger),
  ])(scores)

  if (isEmpty(ss)) {
    return '--'
  }

  return flow([
    mean,
    toFixed(2),
  ])(ss)
}

export const numberAddComma = number =>
  isSafeInteger(number) ? replace(/\B(?=(\d{3})+(?!\d))/g)(',')(number) : number

export const emptyString2Null = val => val === '' ? null : val

export const tText = vm => flow([
  bindKey(vm),
  update('text'),
])('$t')

/**
 * @param {import('axios').AxiosError} error
 */
export const getErrorMessage = error => {
  if (!error) {
    return
  }
  let m = '未知錯誤'
  if (error?.response) {
    const re = error.response
    if (re?.data?.[0]) {
      const rem = re.data[0]
      // EX: "E003409001: Duplicate permission name"
      m = `${rem.code}: ${rem.message}`
    } else {
      // EX: "409: Conflict"
      m = `${re.status}: ${re.statusText}`
    }
  } else {
    // EX: "Request failed with status code 409"
    m = error.message
  }

  return m
}

/**
 * @function
 * @param {string} url
 * @returns {URL}
 */
export const getURL = (url) => {
  if (typeof url !== 'string') return

  try {
    const _url = new URL(url)
    return _url
  } catch (error) {
    console.log({ url, error })
    return
  }
}
