import { flow, has, isEmpty, isNil, isSafeInteger, isString, keyBy, mapValues, overSome, reject, set } from 'lodash/fp'

/**
 * @class Class Params
 */
class Params {
  /** @type {reqData.ParamMember[]} */
  collect = []

  /** @type {reqData.Params} */
  initParams = {}

  /**
   * @function
   * @param {reqData.ParamMember[]} paramMembers
   */
  throwTypeError(paramMembers) {
    console.log('throwTypeError', paramMembers)
    const messages = paramMembers.map(this.getTypeErrorMessage).join('\n')
    throw new TypeError(messages)
  }

  /**
   * @function
   * @param {reqData.ParamMember} paramMember
   */
  getTypeErrorMessage(paramMember) {
    const { name, check, value } = paramMember
    const message = `${name}設定錯誤。預期為${check.name}，實際為${value}`

    return message
  }

  /**
   * @function
   * @param {reqData.ParamMember[]} paramMembers
   */
  checkAll(paramMembers) {
    const qq = this.getFailedVerification(paramMembers)

    if (qq.length === 0) return true

    this.throwTypeError(qq)
    return false
  }

  /**
   * @function
   * @param {reqData.ParamMember[]} paramMembers
   * @returns
   */
  getFailedVerification(paramMembers) {
    const qq = paramMembers.filter((q) => {
      if (typeof q.check === 'function') return q.check(q.value) !== true
      return false
    })

    return qq
  }

  /**
   * @function
   * @returns {reqData.Params}
   */
  getParams() {
    return this.checkAll(this.collect)
      ? flow([
          reject([ 'value', isNil ]),
          keyBy('name'),
          mapValues('value'),
        ])(this.collect)
      : {}
  }

  /**
   * @function
   * @param {reqData.Params} params
   */
  setParams(params) {
    if (isEmpty(params)) return

    this.collect.forEach(p => {
      if (!has(p.name)(params) || p.value === params[p.name]) {
        return
      }
      // console.log(p.name, p.value, params[p.name])

      p.value = params[p.name]
    })
  }

  /**
   * @function
   * @param {reqData.Params} params
   */
  init(params) {
    this.setParams(params)
    this.initParams = params
  }

  reset() {
    this.collect.forEach(set('value')(null))
    this.setParams(this.initParams)
    return this
  }
}

/**
 * @class Class SearchBasic
 * @extends Params
 */
export class SearchBasic extends Params {
  /**
   * @constructor
   * @param {reqData.SearchParams} params
   */
  constructor(params) {
    super()

    this.collect.push(
      {
        name: 'limit',
        check: isSafeInteger,
        value: null,
      },
      {
        name: 'offset',
        check: isSafeInteger,
        value: null,
      },
    )

    this.init(params)
  }
}

/**
 * @class Class SearchKeyword
 * @extends SearchBasic
 */
export class SearchKeyword extends SearchBasic {
  /**
   * @constructor
   * @param {reqData.SearchKeywordParams} params
   */
  constructor(params) {
    super()

    this.collect.push(
      {
        name: 'keyword',
        check: overSome([
          isNil,
          isString,
        ]),
        value: null,
      },
    )

    this.init(params)
  }
}
