import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '@/views/Home/Main.vue'
import Watch from '@/views/Home/Watch.vue'
import Login from '@/views/Pages/Login.vue'
import Fetch from '@/views/Pages/Fetch.vue'
import store from '@/store'
import { isEmpty } from 'lodash/fp'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        isRequiredAuth: true,
      },
      children: [
        {
          path: '',
          name: 'Main',
          component: Main,
        },
        {
          path: '/watch/:videoId',
          name: 'Watch',
          component: Watch,
          meta: {
            isRequiredAuth: true,
          },
          props: true,
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/fetch',
      name: 'Fetch',
      component: Fetch,
    },
    {
      path: '*',
      redirect: {
        name: 'Main',
      },
    },
  ],
})

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(routeRecord => routeRecord.meta.isRequiredAuth)) {
    if (!store.state.auth.accessToken) {
      next({
        name: 'Login',
      })
    } else if (isEmpty(store.state.auth.user)) {
      next({
        name: 'Fetch',
        query: {
          originTo: to.fullPath,
        },
      })
    }
  }
  next()
})

export default router
