<template>
  <v-row
    dense
    align="stretch"
  >
    <v-col
      cols="12"
    >
      <v-row
        no-gutters
        align="center"
      >
        <strong>{{ $t('homeVideos.videos') }}</strong>
        <v-spacer />
        <v-btn
          plain
          color="primary"
          @click="readyReload"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          {{ $t('util.reload') }}
        </v-btn>
      </v-row>
    </v-col>

    <v-col
      cols="12"
    >
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="keyword"
            :placeholder="$t('homeVideos.enterKeyword')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      v-for="(item, index) in showItems"
      :key="index"
      cols="12"
      v-bind="displayCols"
    >
      <v-card
        flat
        tile
        color="transparent"
      >
        <v-sheet>
          <v-responsive
            :aspect-ratio="16/9"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="fill-height"
            >
              <template
                v-if="!item.__isReady"
              >
                <v-img
                  v-if="item.__images"
                  :src="item.__images.mq"
                  :alt="item.name"
                  contain
                />
                <v-icon
                  v-else
                  size="96px"
                >
                  mdi-video
                </v-icon>
              </template>

              <a
                v-else-if="item.__hasRedirect"
                :href="item.url"
                :title="item.name"
                target="_blank"
                rel="noopener noreferrer"
                class="summary-box-two-line text-decoration-none d-flex align-center justify-center"
                @click="handleRegister(item.packedUrl)"
                style="height: 100%; width: 100%;"
              >
                <v-img
                  v-if="item.__images"
                  :src="item.__images.mq"
                  :alt="item.name"
                  contain
                />
                <v-icon
                  v-else
                  size="96px"
                >
                  mdi-video
                </v-icon>
              </a>

              <router-link
                v-else
                :to="{
                  name: 'Watch',
                  params: {
                    videoId: item.__v,
                  },
                }"
                :title="item.name"
                class="summary-box-two-line text-decoration-none d-flex align-center justify-center"
                style="height: 100%; width: 100%;"
              >
                <v-img
                  v-if="item.__images"
                  :src="item.__images.mq"
                  :alt="item.name"
                  contain
                />
                <v-icon
                  v-else
                  size="96px"
                >
                  mdi-video
                </v-icon>
              </router-link>

            </v-row>
          </v-responsive>
        </v-sheet>

        <v-card-title
          class="px-1 pt-1"
        >
          <template
            v-if="!item.__isReady"
          >
            <span
              v-text="item.name"
            />

            <v-spacer />

            <v-chip small>
              {{ $t('util.proccessing') }}
            </v-chip>
          </template>

          <template
            v-else-if="item.__hasRedirect"
          >
            <a
              :href="item.url"
              :title="item.name"
              target="_blank"
              rel="noopener noreferrer"
              class="summary-box-two-line text-decoration-none"
              @click="handleRegister(item.packedUrl)"
            >
              <span
                v-text="item.name"
              />
              <v-icon
                right
                class="vertical-align-text-bottom"
              >
                mdi-export
              </v-icon>
            </a>
          </template>

          <router-link
            v-else
            :to="{
              name: 'Watch',
              params: {
                videoId: item.__v,
              },
            }"
            :title="item.name"
            class="summary-box-two-line text-decoration-none"
          >
            {{ item.name }}
          </router-link>
        </v-card-title>

        <v-card-subtitle
          class="px-1 pb-1"
        >
          <div>
            <span
              v-text="item.__createdAt"
            />
          </div>
          <div>
            <span
              class="text-body-2 text--secondary"
              v-text="`${$t('util.expiredDate')}: ${item.__expiredDate}`"
            />
          </div>
        </v-card-subtitle>

        <v-card-actions
          class="py-0"
        >
          <v-dialog
            v-model="remarkPanel[index]"
            maxWidth="480"
            scrollable
          >
            <template #activator="{ attrs, on }">
              <v-btn
                :disabled="!item.__remark"
                depressed
                v-bind="attrs"
                v-on="on"
              >
                <span
                  v-if="item.__remark"
                  v-text="$t('util.readRemark')"
                />
                <span
                  v-else
                  v-text="$t('util.noRemark')"
                />
              </v-btn>
            </template>

            <v-card
              width="90vw"
              outlined
            >
              <v-card-title
                v-text="item.name"
              />
              <v-card-text
                v-html="item.__remark"
              />
              <v-card-actions />
              <v-btn
                icon
                absolute
                top
                right
                @click="closeRemarkPanel(index)"
                class="mt-n2 mr-n2"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-col>

    <template
      v-if="!hasAllItems"
    >
      <v-col
        :key="items.length"
        v-intersect="readylist"
        cols="12"
      >
        <v-row
          dense
        >
          <v-col
            v-for="index in showCount"
            :key="index"
            cols="12"
            v-bind="displayCols"
          >
            <v-responsive
              :aspect-ratio="16/9"
            >
              <v-skeleton-loader
                tile
                type="image"
                height="100%"
              />
            </v-responsive>
            <v-skeleton-loader
              type="paragraph, button@1"
              class="px-1 pt-2"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <v-col
      v-if="!hasShowMore && needShowMore"
      cols="12"
    >
      <v-row
        no-gutters
        align="center"
      >
        <v-divider />

        <v-btn
          text
          color="primary"
          class="mx-2"
          @click="hasShowMore = true"
        >
          {{ $t('util.showMore') }}
        </v-btn>

        <v-divider />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { SearchBasic } from '@/plugins/params'
import shenlearnAPI, {handleRequest} from '@/plugins/shenlearnAPI'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { videosMap } from '@/store/videos'
import VueMarkdown from 'vue-markdown'
import { VDialog, VMenu } from 'vuetify/lib'
import {baseURL} from '@/config';

export default {
  name: 'HomeVideos',

  components: {
    VueMarkdown,
    VDialog,
    VMenu,
  },

  data() {
    return {
      displayCols: {
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      },
      defaultParams: new SearchBasic({
        limit: 24,
        offset: 0,
      }),
      hasShowMore: false,
      remarkPanel: [],
      keyword: '',
    }
  },

  computed: {
    ...mapGetters(videosMap.namespace, {
      items: videosMap.gettersMap.videoList,
      hasAllItems: videosMap.gettersMap.hasAllItems,
    }),

    showCount: ({ displayCols, $vuetify }) =>
      Math.max(12 / (displayCols[$vuetify.breakpoint.name] ?? 12), 2),

    needShowMore: ({ items, showCount }) =>
      items.length > showCount,

    filteredItems: ({ items, keyword }) => items.filter(i => i.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1),

    showItems: ({ filteredItems, hasShowMore, showCount }) => {
      if (hasShowMore) return filteredItems
      return filteredItems.slice(0, showCount)
    },
  },

  watch: {
    items: {
      handler: 'resetRemarkPanel',
    },
  },

  created() {
    this.readyReload()
  },

  methods: {
    ...mapMutations(videosMap.namespace, {
      setList: videosMap.mutationsMap.setList,
    }),
    ...mapActions(videosMap.namespace, {
      read: videosMap.actionsMap.read,
    }),

    readylist() {
      this.defaultParams.reset().setParams({
        offset: this.items.length,
      })
      this.list()
    },

    readyReload() {
      this.defaultParams.reset()
      this.setList([])
    },

    async list() {
      try {
        const params = this.defaultParams.getParams()
        await this.read(params)
      } catch (error) {
        console.log([error])
      }
    },

    handleRegister(url) {
      // handleRequest(shenlearnAPI.post(url, {}, { params: { isRedirect: false } }))
    },

    /**
     * @param {unknown[]} items
     */
    resetRemarkPanel(items) {
      const a = Array(items.length).fill(false)
      this.remarkPanel = a
    },

    closeRemarkPanel(index) {
      this.$set(this.remarkPanel, index, false)
      // this.remarkPanel[index] = false
    },
  },
}
</script>
